<template>
  <div
    class="bg-dark-gray h-screen w-screen overflow-x-hidden flex justify-center items-center flex-col"
  >
    <h1 class="text-white text-7xl">404</h1>
    <h1 class="text-white text-2xl mt-6">Not found</h1>
    <h1 class="text-white text-base mt-10 mx-6">
      The page requested could not be found on our server
    </h1>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
